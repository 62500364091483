/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

body {
  @apply .text-gray-700 .bg-gray-200 .pb-20;
}
